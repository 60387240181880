<template>
  <div class="container">
    <div class="row">
      <div class="col-12 teste">
        <b-icon icon="lock" aria-hidden="true" scale="4" class="mb-4"></b-icon>

        <span class="mt-2"><strong>Acesso Negado.</strong></span>
        <p>contate pessoa responsável</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.teste {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em;
  color: #cccccc;
}
</style>